<template>
  <!-- <div
    :class="{ home: appName != 'dkPlush', home_dkPlush: appName == 'dkPlush' }"
  >
    <van-overlay :show="browerFlag" @click="controlBrower">
      <img :src="languageConfig[language].wechat" class="img_brower" />
    </van-overlay>
    <header style="text-align: center">
      <img :src="logo" :style="{'width': appName == 'dkPlush' ? '29vw' : '20vw'}" />
      <p style="margin-top: 3vw">
        <img
          :src="languageConfig[language].name"
          style="width: 21vw;"
          v-if="appName != 'dkPlush'"
        />
        <span style="font-size: 6.4vw;color: #333333;font-weight: bold;" v-else>DETOK</span>
      </p>
    </header>
    <div class="center">
      <p
        :class="{
          login: appName != 'dkPlush',
          login_dkPlush: appName == 'dkPlush',
        }"
        @click="apkDownload"
      >
        {{ languageConfig[language].download }}
      </p>
      <p
        :class="{
          login: appName != 'dkPlush',
          login_dkPlush: appName == 'dkPlush',
          marginTop: true,
        }"
        @click="registerMobile"
      >
        {{ languageConfig[language].register }}
      </p>
    </div>
    <div class="registerText" v-if="appName == 'dkPlush'">{{ languageConfig[language].registerText }}</div>
  </div> -->
  <div class="home_dkPlush">
    <van-overlay :show="browerFlag" @click="controlBrower">
      <img :src="languageConfig[language].wechat" class="img_brower" />
    </van-overlay>
    <van-swipe class="swipeBox" :autoplay="5000" indicator-color="white">
      <van-swipe-item>
        <img :src="langInfo.banner1.img" alt="" class="" style="width: 100vw;">
        <div :class="{'cBlue': true, 'swipeText': !isEn,'swipeText_en': isEn}">
          <span>{{ langInfo.banner1.text1 }}</span>
          <span class="cYellow">{{ langInfo.banner1.text2 }}</span>
          <span>{{ langInfo.banner1.text3 }}</span>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <img :src="langInfo.banner2.img" alt="" class="" style="width: 100vw;">
        <div :class="{'cBlue': true, 'swipeText': !isEn,'swipeText_en': isEn}">
          <span>{{ langInfo.banner2.text1 }}</span>
          <span class="cYellow">{{ langInfo.banner2.text2 }}</span>
          <span>{{ langInfo.banner2.text3 }}</span>
          <span class="cYellow">{{ langInfo.banner2.text4 }}</span>
          <span>{{ langInfo.banner2.text5 }}</span>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <img :src="langInfo.banner3.img" alt="" class="" style="width: 100vw;">
        <div :class="{'cBlue': true, 'swipeText': !isEn,'swipeText_en': isEn}">
          <span>{{ langInfo.banner3.text1 }}</span>
          <span class="cYellow">{{ langInfo.banner3.text2 }}</span>
          <span>{{ langInfo.banner3.text3 }}</span>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <img :src="langInfo.banner4.img" alt="" class="" style="width: 100vw;">
        <div :class="{'cBlue': true, 'swipeText': !isEn,'swipeText_en': isEn}">
          <span>{{ langInfo.banner4.text1 }}</span>
          <span class="cYellow">{{ langInfo.banner4.text2 }}</span>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <img :src="langInfo.banner5.img" alt="" class="" style="width: 100vw;">
        <div :class="{'cBlue': true, 'swipeText': !isEn,'swipeText_en': isEn}">
          <span class="cYellow">{{ langInfo.banner5.text1 }}</span>
          <span>{{ langInfo.banner5.text2 }}</span>
          <span class="cYellow">{{ langInfo.banner5.text3 }}</span>
          <span>{{ langInfo.banner5.text4 }}</span>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div style="text-align: center;margin-top: 10.6vw;">
      <template v-if="isiOS">
        <img src="@/common/img/register/ios.png" alt="" style="width: 70.6vw;" @click="apkDownload('ios')">
      </template>
      <template v-else>
        <!-- <img :src="langInfo.btn_google" alt="" style="width: 70.6vw;" @click="apkDownload('google')"> -->
        <img :src="langInfo.btn_apk" alt="" style="width: 70.6vw;margin-top: 5.3vw;" @click="apkDownload('apk')">
      </template>
      <img :src="langInfo.btn_register" alt="" style="width: 70.6vw;margin: 5.3vw 0 17vw 0;" @click="registerMobile">
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { enums } from "@/utils/enum.js";
import { handleSysLang } from "@/utils";
export default {
  data() {
    return {
      inviteCode: "",
      appName: "dkPlush",
      browerFlag: false,
      logo_ls: require("@/common/img/logo_en.png"),
      logo_dp: require("@/common/img/logo_dp.png"),
      languageConfig: {
        "zh-CN": {
          logo: require("@/common/img/logo_en.png"),
          name: require("@/common/img/name_en.png"),
          download: "下载APP",
          register: "注册",
          ios: "IOS版本研发中,敬请期待!",
          wechat: require("@/common/img/webdown.png"),
          registerText:
`1.注册送100原力和1000原子币
2.抢币活动可获得BTC ETH USDT
3.用户等级开启更多权益
4.玩游戏瓜分百万红包币`
        },
        "zh-TW": {
          logo: require("@/common/img/logo_en.png"),
          name: require("@/common/img/name_en.png"),
          download: "下載APP",
          register: "註冊",
          ios: "IOS版本研發中,敬請期待!",
          wechat: require("@/common/img/webdown_tw.png"),
          registerText:
`1.註冊送100原力和1000原子幣
2.搶幣活動可獲得BTC ETH USDT
3.用戶等級開啟更多權益
4.玩遊戲瓜分百萬紅包幣`
        },
        "en": {
          logo: require("@/common/img/logo_en.png"),
          name: require("@/common/img/name_en.png"),
          download: "Download",
          register: "Sign up",
          ios: "IOS Under Development",
          wechat: require("@/common/img/webdown_en.png"),
          registerText:
`1. Sign up for 100 Force and 1000 ATOS
2. The looting activity can obtain BTC ETH USDT
3. User level enables more benefits
4. Play games to divide millions of red envelope coins`
        },
      },
      language: "en",
      params: null, // 撒币大会注册参数
      langConfig: {
        "zh-CN": {
          banner1: {
            img: require("@/common/img/register/zh-cn/b1.png"),
            text1: "DeTok是背靠全球",
            text2: "1200万",
            text3: "用户的原子链旗下面向视频创作者和粉丝的Web3短视频游戏社交平台"
          },
          banner2: {
            img: require("@/common/img/register/zh-cn/b2.png"),
            text1: "每天参与活动，除了获得平台币",
            text2: "原子币",
            text3: "之外还可以获得",
            text4: "比特币，以太坊，USDT",
            text5: "等加密货币"
          },
          banner3: {
            img: require("@/common/img/register/zh-cn/b3.png"),
            text1: "用户等级开启",
            text2: "变现中心，全球节点，NFT",
            text3: "等更多权益"
          },
          banner4: {
            img: require("@/common/img/register/zh-cn/b4.png"),
            text1: "通过手机 做任务 玩游戏 分享好友 获得",
            text2: "加密货币",
          },
          banner5: {
            img: require("@/common/img/register/zh-cn/b5.png"),
            text1: "原子币",
            text2: "致力于为世界提供一种",
            text3: "更安全、更公平、更值得信任",
            text4: "的国际加密货币。"
          },
          btn_google: require("@/common/img/register/zh-cn/btn_google.png"),
          btn_apk: require("@/common/img/register/zh-cn/btn_apk.png"),
          btn_register: require("@/common/img/register/zh-cn/btn_register.png"),
        },
        "zh-TW": {
          banner1: {
            img: require("@/common/img/register/zh-tw/b1.png"),
            text1: "DeTok是背靠全球",
            text2: "1200萬",
            text3: "用戶的原子鏈旗下面向視頻創作者和粉絲的Web3短視頻遊戲社交平臺"
          },
          banner2: {
            img: require("@/common/img/register/zh-tw/b2.png"),
            text1: "每天參與活動，除了獲得平臺幣",
            text2: "原子幣",
            text3: "之外還可以獲得",
            text4: "比特幣，以太坊，USDT",
            text5: "等加密貨幣"
          },
          banner3: {
            img: require("@/common/img/register/zh-tw/b3.png"),
            text1: "用戶等級開啟",
            text2: "變現中心，全球節點，NFT",
            text3: "等更多權益"
          },
          banner4: {
            img: require("@/common/img/register/zh-tw/b4.png"),
            text1: "通過手機 做任務 玩遊戲 分享好友 獲得",
            text2: "加密貨幣",
          },
          banner5: {
            img: require("@/common/img/register/zh-tw/b5.png"),
            text1: "原子幣",
            text2: "致力於為世界提供一種",
            text3: "更安全、更公平、更值得信任",
            text4: "的國際加密貨幣。"
          },
          btn_google: require("@/common/img/register/zh-tw/btn_google.png"),
          btn_apk: require("@/common/img/register/zh-tw/btn_apk.png"),
          btn_register: require("@/common/img/register/zh-tw/btn_register.png"),
        },
        "en": {
          banner1: {
            img: require("@/common/img/register/en/b1.png"),
            text1: "DeTok is a Web3 short video  game social platform for video creators and fans, backed by Atoshi with ",
            text2: "12 million ",
            text3: "users worldwide"
          },
          
          banner2: {
            img: require("@/common/img/register/en/b2.png"),
            text1: "Participate in the activities every day, in addition to the activity platform coin ",
            text2: "ATOS ",
            text3: ", you can also obtain ",
            text4: "Bitcoin, Ethereum, USDT ",
            text5: "and other cryptocurrencies"
          },
          banner3: {
            img: require("@/common/img/register/en/b3.png"),
            text1: "User level Unlock ",
            text2: "cash center,  global node, NFT ",
            text3: "and more"
          },
          banner4: {
            img: require("@/common/img/register/en/b4.png"),
            text1: "Do tasks and play games on your  phone Share friends to get ",
            text2: "cryptocurrency",
          },
          banner5: {
            img: require("@/common/img/register/en/b5.png"),
            text1: "ATOS ",
            text2: "is committed to providing  the world with a ",
            text3: "safer, fairer, and more trustworthy ",
            text4: " international cryptocurrency."
          },
          btn_google: require("@/common/img/register/en/btn_google.png"),
          btn_apk: require("@/common/img/register/en/btn_apk.png"),
          btn_register: require("@/common/img/register/en/btn_register.png"),
        }  
      },
      langInfo: {
        
      },
      isEn: true
    };
  },
  computed: {
    // logo() {
    //   return this.appName == 'dkPlush' ? this.logo_dp : this.logo_ls;
    // }
  },
  methods: {
    handleAgent() {
      var u = window.navigator.userAgent;
      this.isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // IOS
      this.isWeixin = !!/MicroMessenger/i.test(u); // weixin浏览器
    },

    // 浏览器打开
    controlBrower() {
      this.browerFlag = !this.browerFlag;
    },

    // 下载乐唰APK
    apkDownload(type) {
      this.handleNoAndroid(type);
    },

    // 跳转手机号码注册
    registerMobile() {
      // this.handleNoAndroid('register');
      if (this.isWeixin) {
        this.controlBrower();
        return;
      }
      
      let query = {
        inviteCode: this.inviteCode,
        // language: this.language,
      };
      // if (this.appName) {
      //   query["appName"] = this.appName;
      // }
      if (this.params) {
        query['params'] = this.params;
      }
      this.$router.push({
        path: "/registerDeTok",
        query,
      });
    },

    // 处理不同端下载逻辑
    handleNoAndroid(type) {
      // if (this.isWeixin) {
      //   this.controlBrower();
      // } else if (this.isiOS) {
      //   if (this.appName == 'dkPlush' && !register) {
      //     window.location.href = enums.appStore;
      //   } else {
      //     this.iosTos()
      //   }
      // } else if (this.isAndroid) {
      //   window.location.href = this.appName == "dkPlush" ? enums.apkUrl_flutter : enums.DeTokAddress;
      // }
      if(this.isWeixin) { // 微信客户端
        this.controlBrower();
      } else if (this.isiOS) { // ios下载
        // window.location.href = enums.appStore;
        this.downloadApp(enums.appStore)
      } else { // 安卓、浏览器
        if (type === 'apk') {
          if (this.isAndroid) {
            this.downloadApp('https://m.detok.net/#/')
          } else {
            // window.location.href = enums.apkUrl_flutter;
            this.downloadApp(enums.apkUrl_flutter)
          }

        } else if (type === 'google') {
          // window.location.href = enums.google;
          this.downloadApp(enums.google)

        }
      }
    },

    // ios提示
    iosTos() {
      Toast({
        message: this.languageConfig[this.language].download,
        duration: 3000,
      });
    },

    // 下载app 打开外链
    downloadApp(url){
      let u = navigator.userAgent;
			let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
			let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        // console.log("安卓安卓",isAndroid)
        try{
          // console.log("安卓安卓")
          window.Android.downloadSeaAtoshi(url);
        }
        catch(error){
          // flutter 下载
          try {
            downloadSeaAtoshi.postMessage(url)
          } catch (error) {
            window.location.href = url;
          }
        }
      } else if (isiOS) {
        // console.log("苹果苹果",isiOS)
        try{
          window.webkit.messageHandlers.downloadSeaAtoshi.postMessage(url);
        }
        catch(error){
          // console.log("苹果error",error)
          // window.location.href = url;
          // flutter 下载
          try{
            downloadSeaAtoshi.postMessage(url)
          }
          catch(error){
            // console.log("苹果error",error)
            window.location.href = url;
          }
        }
      } else {
        // console.log("安卓安卓else")
        // console.log("苹果else")
        window.location.href = url;
      }
    },

  },

  mounted() {
    this.handleAgent();
    if (this.$route.query.inviteCode) {
      this.inviteCode = this.$route.query.inviteCode;
    }
    if (this.$route.query.appName) {
      this.appName = this.$route.query.appName;
    }
    if (Reflect.has(this.$route.query, 'params')) { // 撒币大会活动注册添加records和活动id
      this.params = this.$route.query.params;
    }
    
    this.language = handleSysLang();
    this.isEn = this.language === 'en';
    this.langInfo = this.langConfig[this.language];

  },
};
</script>
<style scoped>
.home {
  flex: auto;
  padding: 34vw 8vw 0;
  color: #999;
  font-size: 0.2rem;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);

  width: 100%;
  height: 100% !important;
  background: url(../../common/img/bg.png) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
  /* display: flex;
  flex-direction: column; */
}

.home_dkPlush {
  flex: auto;
  /* padding: 34vw 8vw 0; */
  color: #999;
  /* font-size: 0.2rem; */
  /* overflow: hidden; */
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  width: 100%;
  height: 100% !important;
  /* background: url(../../common/img/bg1.png) no-repeat center; */
  /* background-size: cover; */
  background: #190a1f;
  box-sizing: border-box;
}

.center {
  text-align: center;
  margin-top: 35vw;
}

.center img {
  margin: 0.5rem 0 0.38rem;
  width: 1.08rem;
  height: 1.08rem;
}

.center .login {
  height: 0.9rem;
  line-height: 0.9rem;
  color: #fff;
  text-align: center;
  background: linear-gradient(-90deg, #ff6f4d, #fc1849);
  border-radius: 0.08rem;
  font-size: 0.32rem;
}

.login_dkPlush {
  height: 12.8vw;
  line-height: 12vw;
  color: #fff;
  text-align: center;
  background: #7949FF;
  border-radius: 0.08rem;
  font-size: 4.2vw;font-weight: bold;
}

.center .note {
  margin-top: 0.18rem;
  font-size: 0.26rem;
  color: #8b8b8b;
}

.dialog .wrapper {
  margin: 0 auto;
  margin-top: 4rem;
  width: 5.9rem;
  background: #ffffff;
  border-radius: 0.2rem;
}

.dialog .tips,
.dialog .fail {
  padding: 0.4rem 0.46rem 0;
  text-align: center;
}
.dialog .tips h3,
.dialog .fail h3 {
  font-size: 0.34rem;
  font-weight: 600;
  margin-top: 0.4rem;
  margin-bottom: 0.26rem;
  color: #333;
}
.dialog .tips img {
  width: 2.16rem;
  height: 2.16rem;
}
.dialog .tips p {
  font-size: 0.28rem;
  color: #999;
  line-height: 0.42rem;
  text-align: left;
}
.dialog .tips .line {
  margin-top: 0.3rem;
  border-bottom: 0.01rem solid #ededed;
}
.dialog .tips .down {
  text-align: center;
}

.dialog .tips .down span {
  font-size: 0.32rem;
  line-height: 0.88rem;
  color: #fd504d;
}

.marginTop {
  margin-top: 4vw;
}

.img_brower {
  width: 32vw;
  position: absolute;
  right: 5.3vw;
}

.registerText {
  line-height: 7.7vw;font-size: 4vw;color: #333;font-weight: bolder; width: 100%;padding: 4.2vw 5.3vw;
  box-sizing: border-box;background: #fff;border-radius: 2.6vw;margin-top: 5.3vw;white-space: pre-wrap;
}

/* 轮播 */
.swipeBox {
  text-align: center;
}

/deep/.van-swipe {
  /* overflow-y: visible;
  overflow-x: hidden; */
  height: 142vw;
}

/deep/.van-swipe__indicators {
  bottom: 0;
  /* margin-top: 8vw; */
}

/deep/.van-swipe__indicator--active {
  width: 4vw;height: 1.2vw;border-radius: .1vw;border-radius: 1vw;
}

.swipeText {
  position: absolute;top: 0;padding: 17.2vw 12vw 0 12vw;box-sizing: border-box;
  font-size: 4.8vw;font-weight: bold;line-height: 7.7vw;
}

.swipeText_en {
  position: absolute;top: 0;padding: 13.2vw 10.6vw 0 10.6vw;box-sizing: border-box;
  font-size: 4.8vw;line-height: 7.7vw;
}


.cBlue {
  color: #79E7FF;
}

.cYellow {
  color: #FFF774;
}



</style>
